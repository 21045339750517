import { ConnectivityResponse, PrismaSDK, UsabilityResponse } from "@prismadelabs/prismaid";
import { AnimatePresence, motion } from "framer-motion";
import { animate } from "motion";
import { useMotionTimeline } from "motion-hooks";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MotionDiv from "../../../../components/motion/MotionDiv";
import Sound from "../../../../components/Sound";
import ScaledImage from "../../../../components/UI/images/ScaledImage";
import ScaledImageUnpositioned from "../../../../components/UI/images/ScaledImageUnpositioned";
import Spinner from "../../../../components/UI/Spinner";
import { getScaleFactor, getScaleFactorFromLocalStorage } from "../../../../helper/scale";
import useTimeout from "../../../../hooks/useTimeout";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { setRedirectUrl } from "../../../../state/slices/app"
import {
  addSwipeMessage,
  requestInitialTouchMessage,
  resetSwipeMessage,
  setConnectivityStatus,
  setIsActive,
  setProgress,
  setScaleFactor,
} from "../../../../state/slices/swipe";
import { RootState } from "../../../../state/store";
import ProgressBar from "../../ProgressBar";

import BrowserNotSupportedModal from "./modals/BrowserNotSupportedModal";
import DeviceNotSupportedModal from "./modals/DeviceNotSupportedModal";
import GloveModeModal from "./modals/sensitivity/GloveModeModal";
import PointerSpeedModal from "./modals/sensitivity/PointerSpeedModal";
import TouchSensitivityModal from "./modals/sensitivity/TouchSensitivityModal";
import ShouldAddToHomeScreenModal from "./modals/ShouldAddToHomeScreenModal";

import extensionNFT from "../../../../assets/img/03_scan/scan_extension-nft.png";
import extension from "../../../../assets/img/03_scan/scan_extension.png";
import placement from "../../../../assets/img/03_scan/scan_placement.png";
import placementNFT from "../../../../assets/img/03_scan/scan_placement-nft.png";
import swipeArrowNFT from "../../../../assets/img/03_scan/scan_swipe-arrow-nft.png";
import swipeArrow from "../../../../assets/img/03_scan/scan_swipe-arrow.png";
import thumb from "../../../../assets/img/03_scan/scan_thumb.png";

import verified from "../../../../assets/img/03_scan/scan_verified-checkmark.png";

import success from "../../../../assets/sounds/catchyBrightNote.mp3";

interface Props {
  sdk: PrismaSDK;
}

let hasPlayedStartAnimation = false;
// component
const Field = (props: Props) => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation("swipe");

  const cardType = useAppSelector((state: RootState) => state.app.cardType);

  const scaleFactor = useAppSelector((state: RootState) => state.swipe.scaleFactor);
  const isActive = useAppSelector((state: RootState) => state.swipe.isActive);

  const [showModal_TouchSensitivity, setShowModal_TouchSensitivity] = useState(false);
  const [showModal_GloveMode, setShowModal_GloveMode] = useState(false);
  const [showModal_pointerspeed, setShowModal_pointerspeed] = useState(false);
  const [showModal_ShouldAddToHomeScreen, setShowModal_ShouldAddToHomeScreen] = useState(false);
  const [showModal_browserNotSupported, setShowModal_browserNotSupported] = useState(false);
  const [showModal_deviceNotSupported, setShowModal_deviceNotSupported] = useState(false);

  const successSound = useRef(new Sound(success));

  const [errorCount, setErrorCount] = useState(0);

  const [shouldPlayStartAnimation, setShouldPlayStartAnimation] = useState(true);
  const [shouldPlaySwipeAnimation, setShouldPlaySwipeAnimation] = useState(true);

  const [flashRedAlert, setFlashRedAlert] = useState(false);
  const [flashGreenAlert, setFlashGreenAlert] = useState(false);
  const [showVerified, setShowVerified] = useState(false);

  // configure sdk
  useEffect(() => {
    // FIXME remove!
    // iPhone 12Pro
    // var scale = getScaleFactor(460, 3);
    // dispatch(setScaleFactor(scale));
    console.warn(props.sdk.getAPIKey());
    console.warn("expectedCodeHeight", props.sdk.expectedCodeHeight);

    props.sdk.resume();
    let initialisationSubject = props.sdk.getInitialisationSubject().subscribe((response) => {
      console.log("*) initialisationResponse", response);

      if (response.ppi) {
        var scale = getScaleFactor(response.ppi, response.devicePixelRatio);
        if (!Number.isNaN(scale)) {
          dispatch(setScaleFactor(scale));
          if (!hasPlayedStartAnimation) {
            hasPlayedStartAnimation = true;
            playStartAnimation();
          }
        }
      } else {
        setShowModal_deviceNotSupported(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("touchsensitivity")) {
        setShowModal_TouchSensitivity(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("glovemode")) {
        setShowModal_GloveMode(true);
        return;
      }

      if (response.deviceSupport?.requirements?.includes("pointerspeed")) {
        setShowModal_pointerspeed(true);
        return;
      }
    });

    const usabilitySubject = props.sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
      console.log("*) usabilityResponse", response);
      if (response.event === "device_not_supported") {
        setShowModal_deviceNotSupported(true);
        return;
      }
      if (response.event === "browser_not_supported") {
        setShowModal_browserNotSupported(true);
        return;
      }
      if (response.event === "display_too_small_displacement") {
        setShowModal_deviceNotSupported(true);
        return;
      }
      if (response.event === "display_small_should_add_to_home") {
        setShowModal_ShouldAddToHomeScreen(true);
        return;
      }
    });

    const detectionSuccessSubject = props.sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) detection success:", response.description());

      if (response.codeId === "code1") {
        if (response.rawData.redirectUrl) {
          dispatch(setRedirectUrl(response.rawData.redirectUrl))
        };
        clickSuccessButton();
      } else {
        // bad code or swipeError
        clickErrorButton();
      }
    });

    const detectionErrorSubject = props.sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());
      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });

      let hintCode = "";
      // filter for handled error codes
      let codes = response.hints.filter((hint) => {
        return (
          hint.code === "card_unstable" ||
          hint.code === "invalid_signal" ||
          hint.code === "swipe_faster" ||
          hint.code === "swipe_slower" ||
          hint.code === "swipe_without_card"
        );
      });

      if (codes.length > 0) {
        hintCode = codes[0].code;
      }

      switch (hintCode) {
        case "card_unstable":
        case "invalid_signal":
        case "swipe_faster":
        case "swipe_slower":
        case "swipe_without_card":
          dispatch(
            addSwipeMessage({
              title: t("swipe:retry.title"),
              message: t("swipe:" + hintCode + ".body"),
            })
          );
          break;
        default:
          // unknown code or ""
          dispatch(
            addSwipeMessage({
              title: t("swipe:retry.title"),
              message: t("swipe:retry.body"),
            })
          );
          break;
      }

      clickErrorButton();
    });

    const interactionSubject = props.sdk.getInteractionSubject().subscribe((response) => {
      console.log("*) interaction event:", response.event, response.activeSignals);

      switch (response.event) {
        case "started":
          dispatch(setIsActive(true));
          dispatch(requestInitialTouchMessage());

          let btnAll = document.getElementById("stopAnimationsButton") as HTMLButtonElement;
          btnAll.click();

          // TODO set card opacity to 1!

          break;

        case "changed":
          break;

        case "complete":
          dispatch(setIsActive(false));
          dispatch(setProgress(0));

          let btnRestart = document.getElementById("restartAnimationsButton") as HTMLButtonElement;
          btnRestart.click();
          break;

        default:
          break;
      }
    });

    const progressSubject = props.sdk.getProgressSubject().subscribe((response) => {
      console.log("*) progress:", response.progress, response.lastSwipePoint);
      dispatch(setProgress(response.progress));
    });

    const connectivitySubject = props.sdk.getConnectivitySubject().subscribe((response: ConnectivityResponse) => {
      console.log("*) connectivity response:", response.status);

      if (response.status === null) return;

      dispatch(setConnectivityStatus(response.status));
    });

    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      props.sdk.attachToElement(screen);
    }

    return () => {
      initialisationSubject.unsubscribe();
      usabilitySubject.unsubscribe();
      progressSubject.unsubscribe();
      connectivitySubject.unsubscribe();
      detectionSuccessSubject.unsubscribe();
      detectionErrorSubject.unsubscribe();
      interactionSubject.unsubscribe();
      stopAnimations();
      hasPlayedStartAnimation = false;
    };
  }, [props.sdk]);

  useTimeout(
    () => {
      if (!hasPlayedStartAnimation) {
        playStartAnimation();
      }
    },
    shouldPlayStartAnimation ? 1000 : undefined
  );

  useTimeout(() => {
    if (!scaleFactor) {
      let storageFactor = getScaleFactorFromLocalStorage();
      if (storageFactor) {
        dispatch(setScaleFactor(storageFactor));
        if (!hasPlayedStartAnimation) {
          hasPlayedStartAnimation = true;
          playStartAnimation();
        }
      }
    }
  }, 2000);

  useTimeout(
    () => {
      playSwipeAnimation();
    },
    shouldPlaySwipeAnimation ? 2700 : undefined
  );

  // FIXME selector is not updating when called from subscription
  // works fine when using with button onClick
  // temporary workaround: use hidden button
  const clickSuccessButton = () => {
    let btn = document.getElementById("successButton") as HTMLButtonElement;
    btn.click();
  };
  const handleSwipeSuccess = () => {
    props.sdk.pause();
    successSound.current.play();
    // stopAnimations();
    showGreenAlert();
    dispatch(
      addSwipeMessage({
        title: "",
        message: "",
      })
    );
    setShowVerified(true);
    animate("#verified", { opacity: 1 });
    animate(
      "#verifiedContainer",
      { transform: ["scale(1)", "scale(1.5)", "scale(1)"] },
      { repeat: 5, easing: "ease-in-out", duration: 0.9 }
    );

    setTimeout(() => {
      navigate("/success");
    }, 3000);
    // }, 1000);

    setTimeout(() => {
      dispatch(setProgress(0));
      dispatch(resetSwipeMessage());
      dispatch(setIsActive(false));
    }, 3000);
  };

  const clickErrorButton = () => {
    let btn = document.getElementById("errorButton") as HTMLButtonElement;
    btn.click();
  };
  const handleSwipeError = () => {
    if (errorCount >= 4) {
      navigate("/failure", { replace: true });
      setTimeout(() => {
        dispatch(setProgress(0));
        dispatch(resetSwipeMessage());
        dispatch(setIsActive(false));
      }, 1000);
    } else {
      setErrorCount(errorCount + 1);
      showRedAlert();
    }
  };

  const showRedAlert = () => {
    setFlashRedAlert(true);
  };
  const showGreenAlert = () => {
    setFlashGreenAlert(true);
  };

  const { play: playStartAnimation, timelineInstance: startAnimation } = useMotionTimeline([
    ["#placementShell", { left: ["-100%", 0], top: ["30%", 0] }, { duration: 2 }],
    ["#placement", { opacity: 1 }, { duration: 0.7, at: "<" }],
    ["#thumbShell", { opacity: 1 }, { duration: 0.7, at: 2 }],
  ]);

  const { play: playSwipeAnimation, timelineInstance: swipeAnimation } = useMotionTimeline(
    [
      ["#swipeArrowShell", { opacity: [null, 1] }, { duration: 0.1 }],
      [
        "#swipeArrowShell",
        { transform: ["translateY(100%)", "translateY(100%)", "translateY(0)", "translateY(0)"] },
        { duration: 3, easing: "linear" },
      ],
      ["#swipeArrowShell", { opacity: 0 }, { duration: 0.1, easing: "linear" }],
    ],
    { repeat: Infinity }
  );

  const stopAnimations = () => {
    setShouldPlayStartAnimation(false);
    setShouldPlaySwipeAnimation(false);
    startAnimation?.finish();
    swipeAnimation?.finish();
    animate("#placement", { opacity: 1 });
  };

  const restartAnimations = () => {
    setShouldPlaySwipeAnimation(true);
  };

  const closeTouchModalAndRestartAnimation = () => {
    setShowModal_TouchSensitivity(false);
    setShowModal_pointerspeed(false);
    setShowModal_GloveMode(false);
    setShouldPlayStartAnimation(false);
    setShouldPlaySwipeAnimation(false);
    // TODO restart animation
  };

  return (
    <>
      <div id="swipeScreen" className="absolute top-0 left-0 w-screen overflow-hidden height100vh">
        {!scaleFactor && !(scaleFactor > 0) ? (
          <>
            <div className="absolute inset-0 flex items-center justify-center text-cyan">
              <Spinner />
            </div>
          </>
        ) : (
          <>
            <div className="absolute left-0 w-screen height100vh" id="placementShell" style={{ top: 0 }}>
              {cardType === "nft" ? (
                <ScaledImage
                  src={placementNFT}
                  id="placement"
                  alt="placement"
                  horizontalAlign="right"
                  verticalAlign="bottom"
                  horizontalOffset={-50}
                  verticalOffset={-300}
                  scaleFactor={scaleFactor}
                  opacity={0}
                />
              ) : (
                <ScaledImage
                  src={placement}
                  id="placement"
                  alt="placement"
                  horizontalAlign="right"
                  verticalAlign="bottom"
                  horizontalOffset={-50}
                  verticalOffset={-70}
                  scaleFactor={scaleFactor}
                  opacity={0}
                />
              )}
            </div>
            <ProgressBar />
            {flashRedAlert && (
              <AnimatePresence>
                <motion.div
                  className="absolute inset-0 h-screen bg-wine-dark touch-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [100, 0] }}
                  transition={{
                    duration: 0.2,
                    ease: "linear",
                    repeat: 1,
                    repeatDelay: 0.2,
                  }}
                  onAnimationComplete={() => {
                    setFlashRedAlert(false);
                  }}
                />
              </AnimatePresence>
            )}
            {flashGreenAlert && (
              <AnimatePresence>
                <motion.div
                  className="absolute inset-0 h-screen bg-status-green/95 touch-none"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [0, 100, 0] }}
                  transition={{
                    duration: 0.3,
                    ease: "linear",
                    repeat: 5,
                    repeatDelay: 0.2,
                  }}
                  onAnimationComplete={() => {
                    setFlashRedAlert(false);
                  }}
                />
              </AnimatePresence>
            )}

            <ScaledImage
              src={verified}
              id="verified"
              alt="verified"
              horizontalAlign="center"
              verticalAlign="bottom"
              verticalOffset={-1150}
              scaleFactor={scaleFactor}
              className="origin-center"
              opacity={0}
            />

            {isActive && (
              <AnimatePresence>
                <MotionDiv>
                  {cardType === "nft" ? (
                    <ScaledImage
                      src={extensionNFT}
                      id="landing"
                      alt=""
                      horizontalAlign="right"
                      verticalAlign="bottom"
                      horizontalOffset={-269}
                      verticalOffset={-272}
                      scaleFactor={scaleFactor}
                    />
                  ) : (
                    <ScaledImage
                      src={extension}
                      id="landing"
                      alt=""
                      horizontalAlign="right"
                      verticalAlign="bottom"
                      horizontalOffset={-267}
                      verticalOffset={-42}
                      scaleFactor={scaleFactor}
                    />
                  )}
                </MotionDiv>
              </AnimatePresence>
            )}

            {!isActive &&
              (cardType === "nft" ? (
                <div
                  id="swipeArrowContainer"
                  className="absolute overflow-hidden"
                  style={{
                    // size and position of swipeArrow
                    width: scaleFactor * 77 + "px",
                    height: scaleFactor * 580 + "px",
                    right: 0 - -272 * (scaleFactor || 1),
                    bottom: 0 - -342 * (scaleFactor || 1),
                  }}
                >
                  <div id="swipeArrowShell" className="opacity-0">
                    <ScaledImageUnpositioned src={swipeArrowNFT} id="swipeArrow" alt="swipeArrow" scaleFactor={scaleFactor} opacity={0.7} />
                  </div>
                </div>
              ) : (
                <div
                  id="swipeArrowContainer"
                  className="absolute overflow-hidden"
                  style={{
                    // size and position of swipeArrow
                    width: scaleFactor * 77 + "px",
                    height: scaleFactor * 886 + "px",
                    right: 0 - -270 * (scaleFactor || 1),
                    bottom: 0 - -155 * (scaleFactor || 1),
                  }}
                >
                  <div id="swipeArrowShell" className="opacity-0">
                    <ScaledImageUnpositioned src={swipeArrow} id="swipeArrow" alt="swipeArrow" scaleFactor={scaleFactor} opacity={0.7} />
                  </div>
                </div>
              ))}
            <div className="absolute top-0 left-0 w-screen height100vh" id="thumbShell" style={{ opacity: 0 }}>
              {cardType === "nft" ? (
                <ScaledImage
                  src={thumb}
                  id="thumb"
                  alt="thumb"
                  horizontalAlign="left"
                  verticalAlign="bottom"
                  horizontalOffset={-50}
                  verticalOffset={-470}
                  scaleFactor={scaleFactor}
                  className="animate-pulse-slow"
                />
              ) : (
                <ScaledImage
                  src={thumb}
                  id="thumb"
                  alt="thumb"
                  horizontalAlign="left"
                  verticalAlign="bottom"
                  horizontalOffset={-50}
                  verticalOffset={-470}
                  scaleFactor={scaleFactor}
                  className="animate-pulse-slow"
                />
              )}
            </div>
          </>
        )}
      </div>

      <TouchSensitivityModal isOpen={showModal_TouchSensitivity} setIsOpen={closeTouchModalAndRestartAnimation} />
      <GloveModeModal isOpen={showModal_GloveMode} setIsOpen={closeTouchModalAndRestartAnimation} />
      <PointerSpeedModal isOpen={showModal_pointerspeed} setIsOpen={closeTouchModalAndRestartAnimation} />

      <ShouldAddToHomeScreenModal isOpen={showModal_ShouldAddToHomeScreen} setIsOpen={setShowModal_ShouldAddToHomeScreen} />
      <BrowserNotSupportedModal isOpen={showModal_browserNotSupported} setIsOpen={setShowModal_browserNotSupported} />
      <DeviceNotSupportedModal isOpen={showModal_deviceNotSupported} setIsOpen={setShowModal_deviceNotSupported} />

      <div className="absolute z-10 space-x-2 bottom-2">
        <button onClick={() => handleSwipeError()} id="errorButton" className="hidden">
          handleSwipeError
        </button>
        <button onClick={() => handleSwipeSuccess()} id="successButton" className="hidden">
          handleSwipeSuccess
        </button>
        <button
          onClick={() => {
            stopAnimations();
          }}
          id="stopAnimationsButton"
          className="hidden"
        >
          stopSwipeAnimation
        </button>
        <button
          onClick={() => {
            restartAnimations();
          }}
          id="restartAnimationsButton"
          className="hidden"
        >
          restartSwipeAnimation
        </button>
      </div>
    </>
  );
};

export default Field;
