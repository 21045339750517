import { AnimatePresence } from "framer-motion";
import { Routes, Route, useLocation } from "react-router-dom";
import WithInvocationCheck from "./components/layout/WithInvocationCheck";
import WithOrientationNotice from "./components/layout/WithOrientationNotice";

import Failure from "./pages/Failure";
import Start from "./pages/Start";
import Success from "./pages/Success";
import Swipe from "./pages/Swipe";

const RouterComponent = () => {
  const location = useLocation();

  return (
    <div className="touch-pan-y">
      <AnimatePresence exitBeforeEnter>
        <WithInvocationCheck path={location.pathname}>
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<Start />} />
            <Route
              path="/swipe"
              element={
                <WithOrientationNotice>
                  <Swipe />
                </WithOrientationNotice>
              }
            />
            <Route path="/success" element={<Success />} />
            <Route path="/failure" element={<Failure />} />
          </Routes>
        </WithInvocationCheck>
      </AnimatePresence>
    </div>
  );
};

export default RouterComponent;
