import { motion } from "framer-motion";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import useVH from "react-viewport-height";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";
import { useAppDispatch } from "../../state/hooks";
import { setCardType } from "../../state/slices/app";

import sadFace from "../../assets/img/04_verify/34175-sad-face.json";

const Failure = () => {
  useVH();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  const staggerFadeIn = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 1,
        delayChildren: 0.5,
        staggerChildren: 0.5,
      },
    },
  };

  return (
    <MotionDiv>
      <div className="flex flex-col items-center justify-between text-center px-14 minHeight100vh bg-gradient-to-b from-cyan/60 to-white">
        <div></div>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: [0.8, 1.2, 1] }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 90,
            delay: 0.7,
          }}
        >
          <Lottie loop animationData={sadFace} play className="h-28" />
        </motion.div>
        <motion.div variants={staggerFadeIn} initial="hidden" animate="show">
          <motion.div variants={staggerFadeIn}>
            <p className="text-xl font-bold">Ticket could not be verified.</p>
          </motion.div>
          <motion.div variants={staggerFadeIn}>
            <p className="mt-8 text-xl font-light">Please contact your ticket shop for further assistance.</p>
          </motion.div>
        </motion.div>

        <div className="w-full mt-8 mb-8">
          <Button
            onClick={() => {
              dispatch(setCardType(""));
              navigate("/");
            }}
            className="w-full uppercase shadow-lg"
          >
            Try again
          </Button>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Failure;
