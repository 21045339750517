import SensitivityModal from "./SensitivityModal";

interface Props {
  isOpen: boolean;
  setIsOpen: any;
}

const PointerSpeedModal = (props: Props) => {
  return (
    <SensitivityModal
      title="Activate the<br/><b>Pointer speed</b><br/>on your smartphone."
      p1='Open "Settings"<br/>on your device'
      p2='Select "Language and<br/>keyboard"'
      p3='Select "Pointer speed"<br/>and adjust to 100%'
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
    />
  );
};

export default PointerSpeedModal;
