import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type CardType = "ticket" | "nft" | "";

export type AppState = {
  invocationIsValid: boolean;
  cardType: CardType;
  redirectUrl: string
};

const initialState: AppState = {
  // invocationIsValid: true,
  invocationIsValid: false,
  cardType: "",
  // cardType: "ticket",
  // cardType: "nft",
  redirectUrl: ""
};

export const app = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInvocationIsValid: (state) => {
      state.invocationIsValid = true;
    },
    setCardType: (state, action: PayloadAction<CardType>) => {
      state.cardType = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
      console.log("redirectUrl", action.payload);
    },
  },
});

export const appReducer = app.reducer;
export const { setInvocationIsValid, setCardType, setRedirectUrl } = app.actions;
