import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../state/hooks";
import { RootState } from "../../state/store";

interface Props {
  path: string;
  children: ReactNode;
}

const WithInvocationCheck = (props: Props) => {
  let navigate = useNavigate();

  const invocationIsValid = useAppSelector((state: RootState) => state.app.invocationIsValid);

  useEffect(() => {
    const validStartPaths = ["/"];

    if (invocationIsValid) {
      // is valid
      return;
    }

    if (!validStartPaths.includes(props.path)) {
      // is NOT valid, and not a valid start path
      navigate("/", { replace: true });
    }
  }, [props.path]);

  return <>{props.children}</>;
};

export default WithInvocationCheck;
