import clsx from "clsx";
import { motion } from "framer-motion";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import useVH from "react-viewport-height";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { RootState } from "../../state/store";

import success from "../../assets/img/04_verify/64787-success.json";
import arrows from "../../assets/img/04_verify/11514-swipe-down-arrows.json";
import singer from "../../assets/img/04_verify/singer.jpg";
import special from "../../assets/img/04_verify/verified_special-bonus.png";
import poap from "../../assets/img/04_verify/verified_boca-poap.png";
import music from "../../assets/img/04_verify/verify_emojione-v1_music-ascend.png";
import shop from "../../assets/img/04_verify/verify_flat-color-icons_shop.png";
import drink from "../../assets/img/04_verify/verify_twemoji_tropical-drink.png";
import { setCardType } from "../../state/slices/app";

const Success = () => {
  useVH();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const cardType = useAppSelector((state: RootState) => state.app.cardType);
  const redirectUrl = useAppSelector((state: RootState) => state.app.redirectUrl);

  const staggerFadeIn = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 1,
        delayChildren: 0.5,
        staggerChildren: 0.8,
      },
    },
  };

  return (
    <MotionDiv>
      <div className="flex flex-col items-center height100vh bg-gradient-to-b from-cyan/60 to-white">
        <div
          className={clsx(
            "bg-gradient-to-r rounded-bl-[7rem] shadow-xl",
            { "from-wine to-wine-dark": cardType === "ticket" },
            { "from-cyan to-nearBlack": cardType === "nft" }
          )}
        >
          <div
            className="w-screen h-[50vh] bg-center bg-no-repeat bg-cover rounded-bl-[8rem] shadow-xl"
            style={{ backgroundImage: `url(${singer})` }}
          ></div>
          <motion.div variants={staggerFadeIn} initial="hidden" animate="show" className="">
            <motion.div variants={staggerFadeIn} className="-mt-[4.25rem]">
              <Lottie loop animationData={success} play className="w-[8.5rem] mx-auto h-[8.5rem]" />
            </motion.div>

            <motion.div variants={staggerFadeIn}>
              <p className="pt-2 pb-10 text-3xl font-semibold text-center text-white">Original Ticket!</p>
            </motion.div>
          </motion.div>
        </div>

        <div className="flex flex-col justify-center w-full h-full px-10 mt-6 grow">
          <Button
            onClick={() => {
              dispatch(setCardType(""));
              navigate("/");
            }}
            className="w-full uppercase shadow-lg"
          >
            Scan again
          </Button>
        </div>
        {cardType === "nft" && (
          <div>
            <Lottie loop animationData={arrows} play className="h-20 mx-auto" />
          </div>
        )}
      </div>

      <div className="flex flex-col items-center text-center space-y-14 bg-gradient-to-t from-cyan/60 to-white">
        <div className="mt-14">
          <img src={special} className="h-24" />
        </div>

        {cardType === "nft" && (
          <div className="space-y-4">
            <img src={poap} className="h-20 mx-auto" alt="shop" />
            <p>
              This exclusive NFT will grant access to
              <br />
              future community benefits.
            </p>
            <div className="w-screen px-12 mb-8">
              <Button className="w-full shadow-lg"  onClick={() => {
                if (redirectUrl) {
                  window.location.replace(redirectUrl);
                } else {
                  window.location.replace("https://poap.website/");
                  console.log("no redirectUrl");
                }
              }}>
                Claim Event NFT
              </Button>
            </div>
          </div>
        )}

        <div className="space-y-4">
          <img src={shop} className="h-12 mx-auto" alt="shop" />
          <p>
            Visit your local store.
            <br />
            <span className="uppercase">Today only!</span>
          </p>
          <div className="w-screen px-12 mb-8">
            <Button className="w-full shadow-lg" onClick={() => console.log("click")}>
              10% on merchandise
            </Button>
          </div>
        </div>

        <div className="space-y-4">
          <img src={drink} className="h-12 mx-auto" alt="tropical cocktail glass" />
          <p>
            Register for
            <br />
            our event payment app.
          </p>
          <div className="w-screen px-12 mb-8">
            <Button className="w-full shadow-lg" onClick={() => console.log("click")}>
              Free drink
            </Button>
          </div>
        </div>

        <div className="space-y-4">
          <img src={music} className="h-12 mx-auto" alt="music note" />
          <p>
            Exclusively here at
            <br />
            the festival
          </p>
          <div className="w-screen px-12 pb-8">
            <Button className="w-full shadow-lg" onClick={() => console.log("click")}>
              Download bonus track
            </Button>
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Success;
