import { PrismaSDK } from "@prismadelabs/prismaid";

export class TicketSDKSingleton {
  private static instance: TicketSDKSingleton;
  sdk: PrismaSDK;

  constructor() {
    if (TicketSDKSingleton.instance) {
      throw new Error("Error - use TicketSDKSingleton.getInstance()");
    }
    this.sdk = new PrismaSDK(
      process.env.REACT_APP_SDK_API_KEY_TICKET!,
      "https://api-dev.prismade.net/prismaid" // FIXME: remove dev-url
    );
    this.sdk.setTwoFingerHoldingMode(true);
  }

  static getInstance(): TicketSDKSingleton {
    console.warn("getTicketSDK");
    TicketSDKSingleton.instance = TicketSDKSingleton.instance || new TicketSDKSingleton();
    return TicketSDKSingleton.instance;
  }
}

export class NFTSDKSingleton {
  private static instance: NFTSDKSingleton;
  sdk: PrismaSDK;

  constructor() {
    if (NFTSDKSingleton.instance) {
      throw new Error("Error - use NFTSDKSingleton.getInstance()");
    }
    this.sdk = new PrismaSDK(
      process.env.REACT_APP_SDK_API_KEY_NFT!,
      "https://api-dev.prismade.net/prismaid" // FIXME: remove dev-url
    );
    this.sdk.setTwoFingerHoldingMode(true);
  }

  static getInstance(): NFTSDKSingleton {
    console.warn("getNFTSDK");
    NFTSDKSingleton.instance = NFTSDKSingleton.instance || new NFTSDKSingleton();
    return NFTSDKSingleton.instance;
  }
}
