import { PrismaSDK } from "@prismadelabs/prismaid";
import { useEffect, useRef, useState } from "react";
import { NFTSDKSingleton, TicketSDKSingleton } from "../../../SDK";
import { useAppSelector } from "../../../state/hooks";
import { RootState } from "../../../state/store";
import Field from "./Field";

interface Props {}

// component
const FunctionalSwipeField = (props: Props) => {
  const cardType = useAppSelector((state: RootState) => state.app.cardType);

  const nftsdk = useRef(NFTSDKSingleton.getInstance().sdk);
  const ticketsdk = useRef(TicketSDKSingleton.getInstance().sdk);

  const [sdk, setSDK] = useState<PrismaSDK>();

  useEffect(() => {
    cardType === "nft" ? setSDK(nftsdk.current) : setSDK(ticketsdk.current);
  }, [cardType]);

  if (!sdk) return null;
  return <>{cardType === "nft" ? <Field sdk={sdk} key="nft" /> : <Field sdk={sdk} key="ticket" />}</>;
};

export default FunctionalSwipeField;
