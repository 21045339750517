import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import RouterComponent from "./RouterComponent";
import { Provider } from "react-redux";
import { store } from "./state/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

const routing = (
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Router>
          <RouterComponent />
        </Router>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);

// render
ReactDOM.render(routing, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
