import { useNavigate } from "react-router-dom";
import useVH from "react-viewport-height";
import MotionDiv from "../../components/motion/MotionDiv";
import BackButton from "../../components/UI/buttons/BackButton";
import NetworkIndicator from "./NetworkIndicator";
import SwipeField from "./SwipeField";
import SwipeMessage from "./SwipeMessage";

const Swipe = () => {
  useVH();
  let navigate = useNavigate();

  return (
    <MotionDiv>
      <div className="height100vh bg-gradient-to-b from-cyan/60 to-white">
        <SwipeField />
        <NetworkIndicator />
        <SwipeMessage />
        <div className="absolute top-0 left-0">
          <BackButton onClick={() => navigate("/")} />
        </div>
      </div>
    </MotionDiv>
  );
};

export default Swipe;
