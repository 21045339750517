import SensitivityModal from "./SensitivityModal";

interface Props {
  isOpen: boolean;
  setIsOpen: any;
}

const GloveModeModal = (props: Props) => {
  return (
    <SensitivityModal
      title="Activate the<br/><b>Gloves mode</b><br/>on your smartphone."
      p1='Open "Settings"<br/>on your device'
      p2='Select "Smart<br/>assistance"'
      p3='Select "Gloves mode<br/>enable"'
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
    />
  );
};

export default GloveModeModal;
