import clsx from "clsx";
import { ReactNode, useRef } from "react";
import Sound from "../../Sound";

import click from "../../../assets/sounds/klick.mp3";

// types
type Props = {
  onClick?: any;
  href?: string;
  target?: string;
  rel?: string;
  primary?: boolean;
  disabled?: boolean;
  className?: string;
  children: ReactNode;
  color?: "cyan" | "wine";
  justify?: "center" | "between" | "start";
};

export function Button({
  primary = true,
  disabled = false,
  color = "cyan",
  justify = "center",
  onClick = () => {},
  className = "",
  ...props
}: Props) {
  const clickSound = useRef(new Sound(click));

  let styles = clsx(
    "inline-flex items-center px-6 py-3 rounded-full",
    {
      "justify-center": justify === "center",
      "justify-between": justify === "between",
      "justify-start": justify === "start",
    },
    { "cursor-not-allowed": disabled },
    { "": !disabled },
    // primary, enabled
    {
      "text-white": primary && !disabled,
      "bg-cyan": primary && !disabled && color === "cyan",
      "bg-wine-dark": primary && !disabled && color === "wine",
    },
    // primary, disabled
    {
      "text-white bg-opacity-50": primary && disabled,
      "bg-cyan": primary && disabled && color === "cyan",
      "bg-wine-dark": primary && disabled && color === "wine",
    },
    // secondary, enabled
    {
      "bg-opacity-50": !primary && !disabled,
      "text-cyan bg-cyan": !primary && !disabled && color === "cyan",
      "text-wine-dark bg-wine-dark": !primary && !disabled && color === "wine",
    },
    // secondary, disabled
    {
      "bg-white": !primary && disabled,
      "text-cyan": !primary && disabled && color === "cyan",
      "text-wine-dark": !primary && disabled && color === "wine",
    },
    className
  );

  return props.href ? (
    <a className={styles} {...props}>
      {props.children}
    </a>
  ) : (
    <button
      className={styles}
      disabled={disabled}
      onClick={() => {
        clickSound.current.play();
        onClick();
      }}
      target={props.target}
      rel={props.rel}
      {...props}
    >
      {props.children}
    </button>
  );
}

export default Button;
