import { useEffect } from "react";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";
import useVH from "react-viewport-height";
import MotionDiv from "../../components/motion/MotionDiv";
import Button from "../../components/UI/buttons/Button";
import { useAppDispatch } from "../../state/hooks";
import { setInvocationIsValid, setCardType, CardType } from "../../state/slices/app";
import { resetSwipeMessage } from "../../state/slices/swipe";

import handLottie from "../../assets/img/01_start/80604-hand.json";
import soundLottie from "../../assets/img/01_start/87927-sounds-on-black.json";
import bg from "../../assets/img/01_start/start_bg-blurry.png";
import hy from "../../assets/img/01_start/start_Hi.svg";
import logo_boca from "../../assets/img/01_start/start_logo-boca-systems.svg";
import logo_ticketing from "../../assets/img/01_start/start_logo-ticketing.svg";
import ticketNFT from "../../assets/img/01_start/start_ticket-nft.png";
import ticket from "../../assets/img/01_start/start_ticket.png";

const Start = () => {
  useVH();
  const dispatch = useAppDispatch();
  let navigate = useNavigate();

  useEffect(() => {
    dispatch(setInvocationIsValid());
  }, []);

  const handleSelect = (cardType: CardType) => {
    dispatch(setCardType(cardType));
    dispatch(resetSwipeMessage());
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    navigate("/swipe");
  };

  return (
    <MotionDiv>
      <div
        className="flex flex-col justify-between px-8 text-center bg-center bg-no-repeat bg-cover minHeight100vh"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <img src={logo_ticketing} alt="Ticketing - Verification service" className="h-40 mt-8" />
        <div className="flex items-center justify-center space-x-4">
          <img src={hy} alt="hy" className="h-24" />
          <Lottie loop animationData={handLottie} play className="w-20 h-20" />
        </div>
        <p className="text-xl font-bold">
          Verify your original ticket
          <br />
          right here on your smartphone.
        </p>
        <img src={logo_boca} alt="TLS - Boca Systems" className="my-2 h-9" />
        <div className="z-10 flex items-center justify-center">
          <Lottie loop animationData={soundLottie} play className="z-10 -mb-20 -mt-14" />
        </div>
        <div className="z-50 pb-10 mt-16">
          <div className="relative">
            <Button
              onClick={() => {
                handleSelect("ticket");
              }}
              className="z-50 w-full uppercase shadow-lg"
              color="wine"
              justify="start"
            >
              Verify
            </Button>
            <img src={ticket} alt="" className="absolute h-24 pointer-events-none right-5 -top-9 touch-none" />
          </div>

          <div className="relative mt-10">
            <Button
              onClick={() => {
                handleSelect("nft");
              }}
              className="z-50 w-full uppercase shadow-lg"
              justify="start"
            >
              Verify
            </Button>
            <img src={ticketNFT} alt="" className="absolute h-20 pointer-events-none right-5 -top-4 touch-none" />
          </div>
        </div>
      </div>
    </MotionDiv>
  );
};

export default Start;
