import clsx from "clsx";
import { useRef } from "react";
import Sound from "../../Sound";
import click from "../../../assets/sounds/klick.mp3";
import { ChevronLeft } from "react-feather";

type Props = {
  onClick: any;
  className?: string;
};

const BackButton = (props: Props) => {
  const clickSound = useRef(new Sound(click));

  return (
    <button
      className={clsx("p-4 flex", props.className)}
      onClick={() => {
        props.onClick();
        clickSound.current.play();
      }}
    >
      <ChevronLeft size={35} />
    </button>
  );
};

export default BackButton;
