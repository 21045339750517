import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import swipe_en from "./pages/Swipe/translations/en.json";

const resources = {
  en: {
    swipe: swipe_en,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    lng: "en",
    supportedLngs: ["en"],
    fallbackLng: "en",

    fallbackNS: "common",

    // debug: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "u"],
    },
  });

export default i18n;
