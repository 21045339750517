import clsx from "clsx";
import { useAppSelector } from "../../../state/hooks";
import { RootState } from "../../../state/store";

const ProgressBar = () => {
  const progress = useAppSelector((state: RootState) => state.swipe.progress);
  const cardType = useAppSelector((state: RootState) => state.app.cardType);

  return (
    <div
      className={clsx("absolute bottom-0 flex w-screen overflow-hidden bg-opacity-50", cardType === "nft" ? "bg-cyan" : "bg-wine-dark")}
      style={{
        height: `${progress > 5 ? progress : 0}%`,
        touchAction: "none",
      }}
    ></div>
  );
};

export default ProgressBar;
